import { Button } from "@chakra-ui/react";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { FaEdit, FaPlus, FaTrash } from "react-icons/fa";
import { toast } from "sonner";
import * as Yup from "yup";
import { getErrorMessage } from "../../../functions";
import {
  createFactory,
  deleteFactory,
  getFactories,
  updateFactory,
} from "../../../services/v2/factory.api";
import { GapV2 } from "../../../UI/Gap";
import { InputFieldV2 } from "../../../UI/InputField";
import DeleteConfirmationButton from "../../../UI/v2/DeleteConfirmationButton";
import { createBill, getAllBill } from "../../../services/biltyApi";

const Bilty = () => {
  const [factories, setFactories] = useState([]);
  const [trucks, setTrucks] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [locations, setLocations] = useState([]);
  const [editingFactory, setEditingFactory] = useState(null);
  const searchParams = new URLSearchParams(window.location.search);
  const [bills, setBills] = useState([]);
  const component = searchParams.get("component");

  useEffect(() => {
    getFactories(`?where=${JSON.stringify({ type: component })}`).then(
      (res) => {
        setFactories(res.data);
      }
    );
  }, []);

  useEffect(() => {

    getFactories(`?where=${JSON.stringify({ type: "Truck" })}`).then((res) => {
      setTrucks(res.data);
    });
    
    getFactories(`?where=${JSON.stringify({ type: "Company" })}`).then((res) => {
      setCompanies(res.data);
    });
    
    getFactories(`?where=${JSON.stringify({ type: "Locations" })}`).then((res) => {
      setLocations(res.data);
    });
  }, []);

  const initialValues = {
    name: "",
    date: "",
    destination: "",
    truckNo: "",
    lrNo: "",
    ratePerQTL: "",
    advance: "",
    noOfBags: "",
    paymentCharges: "",
    shortageWeight: "",
    freightWeight: "",
    freight: "",
    shortageAmount: "",
    amount: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Location is required").min(3, "Name must be at least 3 characters"),
    date: Yup.date().required("Date is required"),
    destination: Yup.string().required("Destination is required"),
    truckNo: Yup.string().required("Truck No is required"),
    lrNo: Yup.string().required("LR.No is required"),
    ratePerQTL: Yup.number().required("Rate@QTL is required"),
    advance: Yup.number().required("Advance is required"),
    noOfBags: Yup.number().required("No of Bags is required"),
    paymentCharges: Yup.number().required("Payment Charges are required"),
    shortageWeight: Yup.number().required("Shortage Weight is required"),
    freightWeight: Yup.number().required("Freight Weight is required"),
    freight: Yup.number().required("Freight is required"),
    shortageAmount: Yup.number().required("Shortage Amount is required"),
    amount: Yup.number().required("Amount is required"),
  });

  const fields = [
    { labelName: "Date", type: "date", required: true, name: "date" },
   
      {
      labelName: "Company Name",
      type: "select",
      required: true,
      name: "name",
      options: companies.map((company) => ({
        label: company.data.name,
        value: company._id,
      })),
      onCreateOption: async (inputValue) => {
        const newCompany = await createFactory({
          data: {
            name: inputValue,
          },
          type: "Company",
        });
        setCompanies((prevCompanies) => [...prevCompanies, newCompany.data]);
        // Add the new option to the options array
        console.log("New option created:", inputValue);
      },
    },
    {
      labelName: "From",
      type: "select",
      required: true,
      name: "from",
      options: locations.map((location) => ({
        label: location.data.name,
        value: location._id,
      })),
      onCreateOption: async (inputValue) => {
        const newLocation = await createFactory({
          data: {
            name: inputValue,
          },
          type: "Locations",
        });
        setLocations((prevLocations) => [...prevLocations, newLocation.data]);
        // Add the new option to the options array
        console.log("New option created:", inputValue);
      },
    },
    {
      labelName: "To",
      type: "select",
      required: true,
      name: "to",
      options: locations.map((location) => ({
        label: location.data.name,
        value: location._id,
      })),
      onCreateOption: async (inputValue) => {
        const newLocation = await createFactory({
          data: {
            name: inputValue,
          },
          type: "Locations",
        });
        setLocations((prevLocations) => [...prevLocations, newLocation.data]);
        // Add the new option to the options array
        console.log("New option created:", inputValue);
      },
    },
    {
      labelName: "Truck No",
      type: "select",
      required: true,
      name: "truckNo",
      options: trucks.map((truck) => ({
        label: truck.data.name,
        value: truck._id,
      })),
      onCreateOption: async (inputValue) => {
        const newTruck = await createFactory({
          data: { name: inputValue },
          type: "Truck",
        });
        setTrucks((prev) => [...prev, newTruck.data]);
      },
    },
    { labelName: "LR.No", type: "text", required: true, name: "lrNo" },
    { labelName: "Rate@QTL", type: "number", required: true, name: "ratePerQTL" },
    { labelName: "ADV", type: "number", required: true, name: "advance" },
    { labelName: "No Of Bags", type: "number", required: true, name: "noOfBags" },
    { labelName: "Payment Charges", type: "number", required: true, name: "paymentCharges" },
    { labelName: "Shortage Weight", type: "number", required: true, name: "shortageWeight" },
    { labelName: "Freight Weight", type: "number", required: true, name: "freightWeight" },
    { labelName: "Freight", type: "number", required: true, name: "freight" },
    { labelName: "Shortage Amount", type: "number", required: true, name: "shortageAmount" },
    { labelName: "Amount", type: "number", required: true, name: "amount" },
  ];

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        if (editingFactory) {
          const updatedFactory = await updateFactory(editingFactory._id, {
            data: values,
          });
          setFactories((prev) =>
            prev.map((factory) =>
              factory._id === editingFactory._id ? { ...factory, ...updatedFactory.data } : factory
            )
          );
          setEditingFactory(null);
        } else {
          const newFactory = await createFactory({
            data: values,
            type: component,
          });
          setFactories([...factories, newFactory.data]);
        }
        formik.resetForm();
      } catch (error) {
        toast.error(getErrorMessage(error));
      }
    },
  });

  const handleEdit = (factory) => {
    setEditingFactory(factory);
    formik.setValues(factory.data);
  };

  const handleDelete = async (id) => {
    try {
      await deleteFactory(id);
      setFactories((prev) => prev.filter((factory) => factory._id !== id));
    } catch (error) {
      toast.error(getErrorMessage(error));
    }
  };
  const handleformSubmit =async (event) => {
    event.preventDefault();
    const {name,destination, ...rest}=formik.values;
    console.log(rest  )

    console.log(name,destination)
    await createBill(rest);

    console.log(formik.values);
  };

  useEffect(()=>{

    const fetchBills=async()=>{
      const response=await getAllBill()
      console.log(response.data)
      setBills(response.data)
    }
    fetchBills()
  },[])

  return (
    <div className="p-4 space-y-6">
      <GapV2 header={editingFactory ? `Edit ${component}` : `New ${component}`}>
        <form
          onSubmit={handleformSubmit}
          className="space-y-4 bg-gray-50 p-4 rounded-lg shadow-md grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4"
        >
          {fields.map((field, index) => (
            <InputFieldV2
              key={index}
              {...field}
              value={formik.values[field.name]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              inputClass="rounded-lg text-gray-800"
              error={formik.touched[field.name] && formik.errors[field.name]}
              touched={formik.touched[field.name]}
            />
          ))}
          <button
            type="submit"
            className={`${
              editingFactory
                ? "bg-yellow-500 hover:bg-yellow-600"
                : "bg-blue-500 hover:bg-blue-600"
            } text-white px-4 py-2 rounded-lg mt-2 w-1/6 flex gap-2 items-center justify-center`}
          >
            <span>{editingFactory ? "Update" : "Add"}</span>
            <FaPlus />
          </button>
        </form>
      </GapV2>

      <GapV2 header={`Existing ${component}`}>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {bills.map((factory) => (
            <div
              key={factory._id}
              className="bg-white p-4 shadow-md flex flex-col justify-between rounded-lg border border-gray-200"
            >
      
      <div>
                <p className="text-lg font-semibold text-gray-800">
                  Bill ID: {factory._id}
                </p>
                <p  className="text-lg font-semibold text-gray-800">Date: {new Date(factory.date).toLocaleDateString()}</p>
                <p  className="text-lg font-semibold text-gray-800">Freight Weight: {factory.freightWeight}</p>
                <p  className="text-lg font-semibold text-gray-800">Amount: {factory.amount}</p>
                <p  className="text-lg font-semibold text-gray-800">Truck No: {factory.truckNo}</p>
                <p  className="text-lg font-semibold text-gray-800">From: {factory.from}</p>
                <p  className="text-lg font-semibold text-gray-800">To: {factory.to}</p>
              </div>              <div className="flex justify-end gap-2 mt-4">
                <Button
                  onClick={() => handleEdit(factory)}
                  variant="solid"
                  backgroundColor="blue.500"
                  color="white"
                  _hover={{ backgroundColor: "blue.600" }}
                  gap={2}
                  width="full"
                >
                  <FaEdit />
                  <span>Edit</span>
                </Button>
                <DeleteConfirmationButton
                  onConfirm={() => handleDelete(factory._id)}
                  buttonText="Delete"
                  confirmText="This action cannot be undone."
                  confirmTitle="Are you sure?"
                  icon={<FaTrash />}
                />
              </div>
            </div>
          ))}
        </div>
      </GapV2>
    </div>
  );
};

export default Bilty;
