import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { getErrorMessage } from "../functions";
import { setCompanyDetails } from "../redux/companyDetailsSlice";
import { setCustomers } from "../redux/customerSlice";
import { setDealers } from "../redux/dealerSlice";
import { setProducts } from "../redux/productSlice";
import { setItems } from "../redux/qrSettingsSlice";
import { getCompanyDetails } from "../services/companyDetailsApi";
import { getAllCustomers } from "../services/customerApi";
import { getAllDealers } from "../services/dealerApi";
import { getAllProducts } from "../services/productApi";
import { getQrSettings } from "../services/qrSettingsApi";
import Loader from "../UI/Loader";
import Sidebar from "../UI/Sidebar";
import { ROLES } from "../constant";
import { getUser } from "../services/userApi";
import { setUser } from "../redux/authSlice";
import CommonModal from "../components/common/CommonModal";

const Dashboard = () => {
  const { auth } = useSelector((state) => state);
  const { isAuth } = auth;
  const { user } = auth;
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const fetchDealers = async () => {
    try {
      if (!user.isPreRender) return;
      setLoading(true);
      const res = await getAllDealers();
      dispatch(setDealers(res.data));
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const fetchCustomers = async () => {
    try {
      if (!user.isPreRender) return;
      setLoading(true);
      const res = await getAllCustomers();
      dispatch(setCustomers(res.data));
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const fetchProducts = async () => {
    try {
      if (!user.isPreRender) return;
      setLoading(true);
      const res = await getAllProducts();
      dispatch(setProducts(res.data));
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const fetchCompanyDetails = async () => {
    try {
      setLoading(true);
      const res = await getCompanyDetails();
      dispatch(setCompanyDetails(res.data));
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };
  const fetchQRSettings = async () => {
    try {
      if (!user.isPreRender) return;

      setLoading(true);
      const res = await getQrSettings();
      dispatch(setItems(res?.data?.fields || []));
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const fetchUser = async () => {
    try {
      setLoading(true);
      const res = await getUser(user?._id);
      dispatch(
        setUser({
          user: res.data,
        })
      );
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isAuth) {
      const fetchAllPromise = async () => {
        await Promise.all([
          fetchCompanyDetails(),
          fetchCustomers(),
          fetchProducts(),
          fetchDealers(),
          fetchQRSettings(),
          fetchUser(),
        ]);
      };
      fetchAllPromise();
    }
  }, []);
  const navigate = useNavigate();
  const emailList = ["newpowerwork@gmail.com", "sanjayarya9035@gmail.com"];
  useEffect(() => {
    if (isAuth && user?.email && emailList.includes(user?.email)) {
      setIsOpen(true);
    }
  }, []);
  if (!isAuth) navigate("/login");
  if (!user?.isAccountVerified && user?.role !== ROLES.SUPER_ADMIN) {
    navigate("/account-not-verified");
  }
  if (loading) return <Loader />;
  return (
    <>
      <CommonModal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <div className="bg-white w-full  rounded-lg ">
          {/* Modal Title */}
          <h2 className="text-xl font-semibold text-gray-800 mb-4 flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-red-500 mr-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 12h6m2 0a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            Payment Due
          </h2>

          {/* Modal Content */}
          <p className="text-gray-600 mb-6">
            Your payment is due. Please make your payment to continue using our
            services. Thank you for being with us!
          </p>
        </div>
      </CommonModal>
      <Sidebar />
    </>
  );
};

export default Dashboard;
