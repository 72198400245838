import http from "./http";


export const createBill=async (data)=>{
    const response=await http.post('/bill/create',data);
    return response;

}

export const getAllBill=async (data)=>{
    const response=await http.get('/bill/get-all',data);
    return response;

}
